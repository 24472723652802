export const url = {
  //backend: 'http://localhost:2999',
  //frontend: 'http://localhost:3000',
  backend: 'https://haulcheck-dev.earthideal.com:2998',
  frontend: 'https://haulcheck-dev.earthideal.com',
  stripe_key: 'pk_test_WFDEFX0s2MaQcbDoemtS4GMt00iNNZKz6F'
}
export const earthidealURL = 'https://dev.earthideal.com';
export const haulcheckURl = 'https://haulcheck-dev.earthideal.com';
export const assuredURL = 'https://dev.assureddisposal.com';
export const assuredAPIURL = 'https://dev.assureddisposal.com:2997';
export const earthidealAPIURL = 'https://dev.earthideal.com:2999';
//export const assuredAPIURL = 'http://localhost:2998';
//export const earthidealAPIURL = 'http://localhost:2997';

import React, {Fragment, Component} from 'react';

export default class SuccessPopup extends Component{

    constructor(props){
        super(props);

        let useButton = props.useButton;
        let style = props.style;
        let buttonLabel = props.buttonLabel;
        let disallowClose = props.disallowClose;
        let useCancelButton = props.useCancelButton;
        //let appearAboveModal= props.appearAboveModal;
        if (!props.useButton) useButton = false;
        if (!props.style) style="success";
        if (!props.buttonLabel) buttonLabel = "Dismiss";
        if (!props.disallowClose) disallowClose = false;
        if (!props.useCancelButton) useCancelButton = false;
        //if (!appearAboveModal) appearAboveModal=false;
        this.state={
          useButton,
          style,
          buttonLabel,
          disallowClose,
          useCancelButton
        }
    }

    componentWillReceiveProps(newProps){
        //console.log("popup new props");
        //console.log(newProps);
        if (newProps.trigger === true){
            console.log("Popup triggered")
            setTimeout(function() {
              let triggerButtons = document.getElementsByClassName(newProps.popupId + "-trigger")
              for (let i = 0; i < triggerButtons.length; i++){
                if (triggerButtons[i]) triggerButtons[i].click();
              }
            }, 0);;
           

            if(!this.state.useButton){
                setTimeout(function() {
                    newProps.callback();
                }, 0);
            }
           
        }
    }

    onClose = () => {
      if (this.props.closeCallback) this.props.closeCallback();
    }

render(){
  return(
    <Fragment>
      <button id={this.props.popupId + "-trigger"} className={this.props.popupId + "-trigger"} data-toggle="popup" data-target={"#"+this.props.popupId} style={{display: "none"}}>Popup Trigger Button</button>
      <div id={this.props.popupId} className="popup col-12 col-md-3 p-0 pl-0 pr-0" data-position="top-center" data-animation="slide-down" data-autohide={this.state.useButton ? undefined : 5000} style={{zIndex: 5000}}>
          {this.props.disallowClose ? null : 
            <button type="button" className="close" data-dismiss="popup" aria-label="Close"><span aria-hidden="true" onClick={this.onClose}>&times;</span></button>
          }
          
          <div className={"media alert-"+this.state.style+" p-4"}>
              <div className="media-body">
                  <label className="fs2m fw-800 text-capitalize"><i className={"ti ti-check text-"+this.state.style+" mr-2"} aria-hidden="true"></i>{this.props.title}</label>
                  <label className="text-center mb-0 ml-5">{this.props.message}</label>
                  {this.state.useButton || this.state.useCancelButton ? 
                      <p className="text-center mt-5">
                          {this.state.useButton ? 
                             <button className={"btn btn-"+this.state.style+" m-0"} data-dismiss="popup" aria-label="Close" onClick={this.props.callback}>{this.state.buttonLabel}</button>
                          : null}
                          {this.state.useCancelButton ? 
                             <button className={"btn btn-outline-"+this.state.style+" m-0 ml-1"} data-dismiss="popup" aria-label="Close">Cancel</button>
                          : null}
                      </p> 
                  : null}
              </div>
          </div>
      </div>
    </Fragment>)
  }

}
import React, {Component} from 'react';
import jwtDecode from 'jwt-decode';
import ajax from 'superagent';
import { Auth } from 'aws-amplify';
import SuccessPopup from '../common/Popups';
import _ from 'lodash';
import PhoneInput from 'react-phone-input-2'
import "./style.css"

export default class UserAccountInfo extends Component {

    constructor(props){      
        super(props);
        this.state = this.getDefaultState();
    }

    getDefaultState = () => {

       const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        let phone = userDecoded['custom:phone'] || '';
        let companyname = userDecoded['custom:company'] || '';
        let phoneIsPrivate = userDecoded['custom:private'] || false;

        return {
            userInfo: userDecoded,
            username: userDecoded['cognito:username'],
            showEditPhoneNumber: false,
            showPhonePopup: false,
            initPhone: "", //phone + "",
            phone: "",
            companyname,
            phoneIsPrivate,
            userJWT
        }

    }

    componentWillMount = () => {
        let {companyname, phone, username, userJWT} = this.state;
        let {eiBackend} = this.props;

        this.setState(this.getDefaultState() , () => {

            ajax.get(`${eiBackend}/users/mycontactinfo/${userJWT}`)
            .end((error, response) => {
                if (!error && response) {
                    console.log("user info",response.body.info)
                    let {phonenumber, companyname, phonenumber_isprivate} = response.body.info;
                    this.setState({phone: phonenumber, initPhone: phonenumber, companyname, phoneIsPrivate: phonenumber_isprivate});
                } else {
                    console.log('There was an error fetching', error);
                }
            })

        })
        
    }

    updatePhoneNumber = async (e) => {
        e.preventDefault();
        let {eiBackend} = this.props;
        let {phone, username} = this.state;

        let currentCognitoUser = await Auth.currentUserPoolUser();
        console.log("currentCognitoUser", currentCognitoUser);

        let result = await Auth.updateUserAttributes(
          currentCognitoUser,
          {
            'custom:phone': phone,
          }
        )

        console.log("AWS update result: ", result);

        if (result === "SUCCESS"){

          ajax.post(`${eiBackend}/users/update/phonenumber`)
          .send({
            username, phone
          })
          .end((error, response) => {
            if (!error && response) {
              this.setState({showPhoneSuccessPopup: true, initPhone: phone, showEditPhoneNumber: false}/*, 
                 () => this.resetJwtToken({'custom:phone': phone})
              */);

              //document.getElementById("close-"+id).click();
            } else {
                console.log('There was an error fetching', error);
            }
          }
        );

        } else {
          alert("Couldn't update AWS, result: " + result);
        }

        console.log("currentCognitoUser", currentCognitoUser);

    }

    togglePrivate = async (e) => {
        let {eiBackend} = this.props;

        e.preventDefault();
        console.log("Calling togglePrivate")

        let {userInfo, phoneIsPrivate} = this.state;

        let isPrivate = (phoneIsPrivate ? '0' : '1');

        console.log("togglePrivate isPrivate", isPrivate);
        let currentCognitoUser = await Auth.currentUserPoolUser();
        console.log("currentCognitoUser", currentCognitoUser);

        let result = await Auth.updateUserAttributes(
            currentCognitoUser,
            {
                'custom:private': isPrivate
            }
        )

        console.log("togglePrivate AWS update result: ", result);

        if (result === "SUCCESS"){

            ajax.post(`${eiBackend}/users/update/contactinfo`)
            .send({
                username: userInfo['cognito:username'], 
                isPrivate
            })
            .end((error, response) => {
                if (!error && response) {
                    this.setState({showPopup: true, phoneIsPrivate: !phoneIsPrivate}/*, () => this.resetJwtToken({"custom:private": !phoneIsPrivate})*/);
                } else {
                console.log('There was an error fetching', error);
                }
            });
        }


     
    }

    formatPhoneNumber = (entry = '') => {
        if (entry.length < 10) return entry;
        
        const match = entry
            .replace(/\D+/g, '').replace(/^1/, '')
            .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
        const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match
        const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
        const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''    
        return `${part1}${part2}${part3}`
    }

    /*resetJwtToken = async (updateObj) => {
        let user = await Auth.currentUserPoolUser();
        console.log(user);
    }*/

    render = () => {

        let {userInfo, showPopup, showPhoneSuccessPopup, showEditPhoneNumber} = this.state; 

        return(<div>
            <div className="section">
                <div>
                    <h3>User Account Info</h3>
                </div>
                <div>
                    Name: { userInfo.name }
                </div>
                <div>
                    Email: { userInfo.email }
                </div>
                <div>
                    Username: { userInfo["cognito:username"] }
                </div>
                <div>
                    { showEditPhoneNumber === false ? <>
                        Mobile Phone Number: { this.formatPhoneNumber(this.state.initPhone) }
                        <small className="cursor ml-3" onClick={() => this.setState({showEditPhoneNumber: true})}>✏️ <a href="#"> Edit</a></small>
                    </> : <>{/*<div class="input-group">
                        <input type="phone" 
                            className="form-control" 
                            value={this.state.phone} 
                            onChange={(e) => this.setState({phone: e.target.value})} />
                            <div class="input-group-append">
                                <button class="btn btn-outline-success" onClick={this.updatePhoneNumber}>Submit</button>
                                <button class="btn btn-outline-secondary" onClick={() => this.setState({
                                    showEditPhoneNumber: false, 
                                    phone: this.state.initPhone
                                })}>Cancel</button>
                            </div>
                    </div>*/}

                    <div class="input-group">
                        <PhoneInput 
                            country='us'
                            onlyCountries={['us']}
                            id="phonenumber"
                            label="Cell Number"
                            name="phonenumber"
                            containerClass="form-control"
                            inputClass="fs-13"
                            dropdownClass="fs-13"
                            placeholder="Cell Number"
                            value={this.state.phone} 
                            onChange={phone => this.setState({phone})}
                        />
                         <div class="input-group-append">
                                <button class="btn btn-outline-success" onClick={this.updatePhoneNumber}>Submit</button>
                                <button class="btn btn-outline-secondary" onClick={() => this.setState({
                                    showEditPhoneNumber: false, 
                                    phone: this.state.initPhone
                                })}>Cancel</button>
                            </div>
                    </div>

                    
                </>}
                </div>
                <div>
                    Company Website: { this.state.companyname }
                </div>

                <p class="small">Your information will only be shared with users who are connected to you. You can change your privacy settings at any time.</p>

                <div className="switch" onClick={async (e) => await this.togglePrivate(e)}>
                    <input type="checkbox" 
                        name="optingIn" 
                        id="optingIn" 
                        className="switch-input" 
                        checked={this.state.phoneIsPrivate}
                      
                    />
                    <label className="switch-label">Keep my contact info private</label>
                </div>

                <p className="small-3">You must log out and log back in for any changes to take effect</p>


            </div>

            <SuccessPopup
                popupId="popupPrivacyUpdated"
                title="Success!"
                message="Privacy Info Info Updated"
                trigger={showPopup}
                callback={() => {
                  this.setState({showPopup: false})
                }}
              />

            <SuccessPopup
                popupId="popupPhoneUpdated"
                title="Success!"
                message="Phone Number Info Updated"
                trigger={showPhoneSuccessPopup}
                callback={() => {
                  this.setState({showPhoneSuccessPopup: false})
                }}
              />
        </div>);
    }

}


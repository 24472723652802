import React from 'react';
import StripeForm from '../common/CCForm/StripeComponents'
import {Elements, StripeProvider} from 'react-stripe-elements';

export default function BillingInfo({stripeKey}) {

    return(
        <StripeProvider apiKey={stripeKey}>
          <Elements>
            <StripeForm getStripePaymentMethod={() => {return true}} returnValidPaymentInfo={() => {return true}} />
          </Elements>
        </StripeProvider>
    )

}
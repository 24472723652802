import React, {useEffect, useRef} from 'react';

export function usePrevious(value, initial) {
  const ref = useRef({target: value, previous: initial});

  if (ref.current.target !== value) {
    // The value changed.
    ref.current.previous = ref.current.target;
    ref.current.target = value;
  }

  return ref.current.previous;
}

//With thanks to https://dev.to/chrismilson/problems-with-useprevious-me
import React, {Fragment, Component} from 'react';
import ajax from 'superagent';
import jwtDecode from 'jwt-decode';
import { Auth } from 'aws-amplify';
import SuccessPopup from '../common/Popups';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/plain.css'

export default class PhoneNumbercompany extends Component {

    constructor(props){      
        super(props);

        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        let phone = userDecoded['custom:phone'] || '';
        let company = userDecoded['custom:company'] || '';

        let phoneIsPrivate = false;

        this.state={
            company,
            validcompany: true,
            phone,
            phoneIsPrivate,
            validPhone: true,
            validForm: true,
            id: "phone-number-company-name",
            displaySpinner: false,
            username: userDecoded['cognito:username'],
            popupTrigger: false
        }
    }

    componentDidMount = () => {
      let {id, company, phone, username} = this.state;
      let {markAsSeen, eiBackend} = this.props;

      if (company == '' && phone == ''){
        ajax.get(`${eiBackend}/users/mycontactinfoexists/${username}`)
          .end((error, response) => {
            if (!error && response) {
                if (response.body.exists) markAsSeen();
                else document.getElementById("open-"+id).click();
            } else {
                console.log('There was an error fetching', error);
            }
          }
        );   
      }

      //Force the modal to open for debug/testing purposes
      //document.getElementById("open-"+id).click();
    }

    handleChange = (e) => {
      this.setState({[e.target.name]: e.target.value})
    }

    validate = () => {
      console.log("PhoneNumbercompany", "calling validate");
      let {company, phone} = this.state;

      let validcompany = this.validateCompany() && company != '';

      this.setState({validcompany, validPhone: !!phone});


      return validcompany && !!phone;
    }

    validateCompany = () => {
      let urlTest = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      return this.validateField("company", "validcompany", RegExp(urlTest), "Company website format is invalid");
    }

    validateField = (val, validVal, regex, message) => {
      let formVal = this.state[val];
      if (!formVal.match(regex) || formVal == ''){
        this.setState({[validVal]: false});
        return false;
      } else {
        this.setState({[validVal]: true});
        return true;
      }
  }

    cleanCompany = async () => {
      let {company} = this.state;
      company = company.replace(/^\/\/|^.*?:(\/\/)?/, '');
      await this.setState({company});
    }

    cleanPhoneNumber = async () => {
      let {phone} = this.state;
      var cleaned = ('' + phone).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        phone = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        this.setState({phone});
      }
    }

    submit = async () => {
      let {company, phone, phoneIsPrivate, username, id} = this.state;
      let {markAsSeen, eiBackend} = this.props;

      let isPrivate = (phoneIsPrivate ? '1' : '0')
      if (this.validate()){
        //this.setState({displaySpinner: true});
        await this.cleanCompany();
        await this.cleanPhoneNumber();
        let {company, phone} = this.state;
        let currentCognitoUser = await Auth.currentUserPoolUser();
        console.log("currentCognitoUser", currentCognitoUser);

        let result = await Auth.updateUserAttributes(
          currentCognitoUser,
          {
            'custom:company': company,
            'custom:phone': phone,
            'custom:private': isPrivate
          }
        )

        console.log("AWS update result: ", result);

        if (result === "SUCCESS"){

          ajax.post(`${eiBackend}/users/update/contactinfo`)
          .send({
            companyname: company, phone, username, isPrivate
          })
          .end((error, response) => {
            if (!error && response) {
              this.setState({popupTrigger: true});
              document.getElementById("close-"+id).click();
            } else {
                console.log('There was an error fetching', error);
            }
          }
        );

        } else {
          alert("Couldn't update AWS, result: " + result);
        }       

      }
     
    }


    render(){
        let {id, phone, company, validPhone, validcompany} = this.state;
        return(<Fragment>
          <div style={{display: "none"}}><button id={"open-"+id} data-toggle="modal" data-target={"#"+id}/></div>

          <div class="modal fade" style={{zIndex: '2000', backgroundColor: "rgba(84,84,84,0.3)"}} data-backdrop="static" id={id} tabIndex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">

                  <div style={{display: "none"}}><button type="button" id={"close-"+id} data-dismiss="modal" className="close" data-dismiss="modal" aria-label="Close" >Close Button</button></div>

                  <h5 class="modal-title" id="exampleModalLabel">Please enter phone number and company...</h5>
                </div>
                <div class="modal-body">
                  <form>

                    <div class="form-group">
                      <label htmlFor="phone">Mobile Phone Number</label>
                      {/*<input type="text" 
                        className={"form-control"+ (!validPhone ? " is-invalid" : "")} 
                        id="phone"
                        name="phone" 
                        value={this.state.phone}
                        onChange={this.handleChange}
                        placeholder="..." 
                      />*/}
                      <PhoneInput
                        inputClass={"form-control"+ (!validPhone ? " is-invalid" : "")} 
                        inputStyle={{
                          width: 'calc(100% - 39px)', 
                          height: 'calc(2.53125rem + 2px)',
                          border: '1px solid #eaeff4',
                          padding: '0.375rem 0.75rem',
                          marginLeft: '39px'
                        }}
                        buttonStyle={{
                          border: '1px solid #eaeff4',
                        }}
                        defaultCountry={'us'}
                        onlyCountries={['us']}
                        value={this.state.phone}
                        onChange={phone => this.setState({ phone })}
                      />
                    </div>

                    <div class="form-group">
                      <label htmlFor="company">Company Website</label>
                      <input 
                        type="text" 
                        className={"form-control"+ (!validcompany ? " is-invalid" : "")}
                        id="company"
                        name="company"
                        value={this.state.company}
                        onChange={this.handleChange}
                        onBlur={this.validateCompany}
                        placeholder="earthideal.com"
                      />
                    </div>

                    <div className="switch" onClick={(e) => {
                      e.preventDefault();
                      this.setState({phoneIsPrivate: !this.state.phoneIsPrivate });
                    }}>
                      <input type="checkbox" 
                        name="phoneIsPrivate" 
                        id="phoneIsPrivate" 
                        className="switch-input" 
                        checked={this.state.phoneIsPrivate}
                      />
                      <label className="switch-label">Keep my contact info private</label>
                  </div>

                  <p class="small">Your information will only be shared with users who are connected to you. You can change your privacy settings at any time.</p>

                  </form>
                </div>
                <div class="modal-footer">
                  <button 
                    type="button"
                    class="btn btn-primary" 
                    onClick={this.submit}
                    >
                      Enter
                    </button>
                </div>
              </div>
            </div>
          </div>

           <SuccessPopup
                popupId="popupInfoUpdated"
                title="Success!"
                message="Contact Info Updated"
                trigger={this.state.popupTrigger}
                callback={() => {
                  this.setState({popupTrigger: false});
                }}
              />
        </Fragment>)
    }



}

